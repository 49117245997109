import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelectedStore } from '../context/SelectedStoreContext';
import axios from 'axios';
import '../styles/StoreFeed.css';
import mcdonald from '../images/mcdonalds.png';
import dennys from '../images/dennys.png';
import poinciana from '../images/poinciana.png';

function StoreFeed({ store }) {
  const navigate = useNavigate();
  const { selectStore } = useSelectedStore();
  const [status, setStatus] = useState({ online: true, message: '' });

  useEffect(() => {
    // Fetch general status from the server
    const fetchGeneralStatus = async () => {
      try {
        // Use process.env to access environment variables
        const serverEndpoint = process.env.NODE_ENV === 'development'
          ? 'http://localhost:3001/status'
          : 'https://poinciana-bite-2e380da165ed.herokuapp.com/status';
  
        const response = await axios.get(serverEndpoint);
        setStatus(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching general status:', error);
      }
    };
  
    fetchGeneralStatus();
  }, []);
  

  const handleOnClick = (destination) => {
    if (!status.online) {
      // If the store is offline, do nothing
      return;
    }

    selectStore(store);
    navigate(destination);
  };

  let img;
  switch (store.id) {
    case 1:
      img = mcdonald;
      break;
    case 2:
      img = dennys;
      break;
    case 999:
      img = poinciana;
      break;
    default:
      break;
  }

  return (
    <div className={`store-feed ${!status.online ? 'offline' : ''}`} onClick={() => status.online ? handleOnClick(`/store/${store.id}`) : null}>
    <div className="image-container">
      <img src={img} alt={store.name} className="store-image" />
      {!status.online && (
        <div className="offline-overlay">
          <p>{status.statusMessage}</p>
        </div>
      )}
    </div>
    <h2 className="store-name">{store.name}</h2>
  </div>
  );
}

StoreFeed.propTypes = {
  store: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    // Add other store details as needed
  }).isRequired,
};

export default StoreFeed;
