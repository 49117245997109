// OrderModal.js
import React, { useState } from 'react';
import axios from 'axios';

function OrderModal({ closeModal, completePendingOrder, selectedPendingOrder }) {
  // State for the modal inputs and calculated values
  const [price, setPrice] = useState('');
  const [pickupAddress, setPickupAddress] = useState('');
  const [subtotal, setSubtotal] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [tip, setTip] = useState(0);
  const [total, setTotal] = useState(0);
    console.log(selectedPendingOrder)
  // Function to calculate the price and other values
  const calculatePrice = async () => {
    try {
      // Send a request to the backend to handle price calculation
    const serverEndpoint = process.env.NODE_ENV === 'development'
    ? 'http://localhost:3001/calculate-price'
    : 'https://poinciana-bite-2e380da165ed.herokuapp.com/calculate-price';

  const response = await axios.post(serverEndpoint, {
    price: parseFloat(price), // Assuming price is a string, convert it to a number
    selectedPendingOrder,
    pickupAddress,
  });
      const { calculatedSubtotal, calculatedDeliveryFee, calculatedTaxes, calculatedServiceFee } = response.data;
      // Update state with calculated values
      setSubtotal(calculatedSubtotal);
      setDeliveryFee(calculatedDeliveryFee);
      setTaxes(calculatedTaxes);
      setServiceFee(calculatedServiceFee);
      setTotal(calculatedSubtotal + calculatedDeliveryFee + calculatedTaxes + calculatedServiceFee)

      // Optionally, set the calculated total as the default price in the input field
      setPrice(calculatedSubtotal.toFixed(2));
    } catch (error) {
      console.error('Error calculating price on the backend:', error);
    }
  };

  const submitOrder = async () => {
    try {
      // Assuming selectedPendingOrder contains orderId and userPhoneNumber
      const { _id, phoneNumber } = selectedPendingOrder;

      // Call the server endpoint to send the checkout link via SMS
      const response = await axios.post('http://localhost:3001/send-checkout-link', {
        orderId: _id,
        phoneNumber: phoneNumber,
      });
  
      console.log('Checkout link sent successfully:', response.data);
    } catch (error) {
      console.error('Error submitting order:', error);
    }
  };
  

  return (
    <div className="order-modal">
      {/* Modal content */}
      <h2>Complete Order</h2>
      <label htmlFor="price">Price:</label>
      <input
        type="text"
        id="price"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
      />
      <label htmlFor="pickupAddress">Pickup Address:</label>
      <input
        type="text"
        id="pickupAddress"
        value={pickupAddress}
        onChange={(e) => setPickupAddress(e.target.value)}
      />
      {/* Button to calculate the price and other values */}
      <button onClick={calculatePrice}>Calculate Price</button>

      {/* Display calculated values */}
      <div>
        <p>Subtotal: ${subtotal.toFixed(2)}</p>
        <p>Delivery Fee: ${deliveryFee.toFixed(2)}</p>
        <p>Taxes: ${taxes.toFixed(2)}</p>
        <p>Service Fee: ${serviceFee.toFixed(2)}</p>
        <p>Tip: ${tip.toFixed(2)}</p>
        <p>Total: ${total.toFixed(2)}</p>
      </div>

      {/* Button to submit the order */}
      <button onClick={submitOrder}>Send Order Link</button>
      {/* Button to close the modal */}
      <button onClick={closeModal}>Close</button>
    </div>
  );
}

export default OrderModal;
