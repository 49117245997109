import { createContext, useContext, useState, useCallback, useEffect } from 'react';

const CurrentOrderContext = createContext();

export function useCurrentOrder() {
  return useContext(CurrentOrderContext);
}

export function CurrentOrderProvider({ children }) {
  const [currentOrders, setCurrentOrders] = useState({});

  // Load order from local storage on component mount
  useEffect(() => {
    const storedOrders = localStorage.getItem('currentOrders');
    if (storedOrders) {
      setCurrentOrders(JSON.parse(storedOrders));
    }
  }, []);

  const clearLocalStorage = useCallback(() => {
    // Clear local storage
    localStorage.removeItem('currentOrders');
  }, []);

  const addToOrder = useCallback((storeId, item) => {
    setCurrentOrders((prev) => {
      const updatedOrders = {
        ...prev,
        [storeId]: [...(prev[storeId] || []), item],
      };

      // Save to local storage
      localStorage.setItem('currentOrders', JSON.stringify(updatedOrders));

      return updatedOrders;
    });
  }, []);

  const updateQuantity = useCallback(
    (storeId, itemIndex, newQuantity) => {
      // Ensure quantity doesn't go below 1
      if (newQuantity < 1) newQuantity = 1;

      setCurrentOrders((prev) => {
        // Get the current item
        const currentItem = prev[storeId][itemIndex];

        // Calculate the new total cost based on the item's base price and new quantity
        const newTotalCost = (currentItem.totalCost / currentItem.quantity) * newQuantity;

        // Update only the quantity and totalCost properties of the item
        const updatedItem = { ...currentItem, quantity: newQuantity, totalCost: newTotalCost };

        // Replace the item in the store's order list with the updated item
        const updatedStoreItems = [...prev[storeId]];
        updatedStoreItems[itemIndex] = updatedItem;

        const updatedOrders = {
          ...prev,
          [storeId]: updatedStoreItems,
        };

        // Save to local storage
        localStorage.setItem('currentOrders', JSON.stringify(updatedOrders));

        return updatedOrders;
      });
    },
    []
  );

  const deleteFromOrder = useCallback((storeId, itemIndex) => {
    setCurrentOrders((prev) => {
      const updatedStoreItems = [...prev[storeId]];
      updatedStoreItems.splice(itemIndex, 1);

      if (updatedStoreItems.length === 0) {
        const updatedOrders = { ...prev };
        delete updatedOrders[storeId];

        // Save to local storage
        localStorage.setItem('currentOrders', JSON.stringify(updatedOrders));

        return updatedOrders;
      } else {
        const updatedOrders = {
          ...prev,
          [storeId]: updatedStoreItems,
        };

        // Save to local storage
        localStorage.setItem('currentOrders', JSON.stringify(updatedOrders));

        return updatedOrders;
      }
    });
  }, []);

  return (
    <CurrentOrderContext.Provider value={{ currentOrders, addToOrder, updateQuantity, deleteFromOrder, setCurrentOrders, clearLocalStorage }}>
      {children}
    </CurrentOrderContext.Provider>
  );
}
