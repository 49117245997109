import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../styles/MenuItemModal.css';
import DoneButton from './DoneButton';
import PhoneNumberSection from './PhoneNumberSection'; // Import PhoneNumberSection
import { useCurrentOrder } from '../context/CurrentOrderContext';
import { useSelectedStore } from '../context/SelectedStoreContext';
import { useAddressSelection } from '../context/AddressSelectionContext'
import axios from 'axios';


function MenuItemModal({ item, onClose, onAddToOrder }) {
  const { selectedStore } = useSelectedStore();
  const { selectedAddress, getStoredAddress } = useAddressSelection();
  const { addToOrder } = useCurrentOrder();
  const [quantity, setQuantity] = useState(1);
  const [customizations, setCustomizations] = useState({});
  const [totalCost, setTotalCost] = useState(item.price);
  const [requiredCustomizationsSelected, setRequiredCustomizationsSelected] = useState(false);
  const [showPhoneNumberSection, setShowPhoneNumberSection] = useState(false); // State to manage PhoneNumberSection display

  useEffect(() => {
    // Check if all required customizations are selected
    const areRequiredCustomizationsSelected = item.customizations.every(customizationSection => {
      return Object.keys(customizationSection).every(sectionName => {
        const section = customizationSection[sectionName];
        return !section.required || (customizations[sectionName] && Object.keys(customizations[sectionName]).length > 0);
      });
    });

    // Update the state based on the check
    setRequiredCustomizationsSelected(areRequiredCustomizationsSelected);

    // Update the total cost based on customizations and quantity
    let additionalCost = 0;
    for (let section in customizations) {
      for (let optionName in customizations[section]) {
        const selectedOptions = item.customizations[0][section].options[optionName].filter(
          o => customizations[section][optionName].includes(o.option)
        );
        additionalCost += selectedOptions.reduce((acc, selectedOption) => acc + selectedOption.price, 0);
      }
    }
    if (selectedStore.id != 999)
      setTotalCost((item.price + additionalCost) * quantity); // Multiply by quantity here
  }, [customizations, item, quantity]);

  const handleAddQuantity = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  const handleSubtractQuantity = () => {
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
    }
  };

  const handleAddToOrderClick = async () => {
    if (requiredCustomizationsSelected) {
      if (selectedStore.id === 999) {
        // Check if userPhoneNumber is in local storage
        const storedPhoneNumber = localStorage.getItem('userPhoneNumber');

        if (storedPhoneNumber) {
          // User phone number is already saved
          alert('Thank you! You will be contacted soon.');

          const pendingOrderData = {
            address: selectedAddress,
            phoneNumber: storedPhoneNumber,
            pickupRestaurant: customizations['Enter Store Name']['Pickup Restaurant']
          };

          // Send pending order data to the server
          await savePendingOrderToServer(pendingOrderData);

          // Remove phone number from local storage
          localStorage.removeItem('userPhoneNumber');

          // Do not add to order
          onClose();
        } else {
          // Show PhoneNumberSection
          setShowPhoneNumberSection(true);
        }
      } else {
        // For other stores, proceed with adding to order
        addToOrder(selectedStore.id, {
          ...item,
          customizations,
          totalCost,
          quantity,
        });
        onClose();
      }
    } else {
      alert('Please select all required customizations.');
    }
  };

  // Function to send pending order data to the server
  const savePendingOrderToServer = async (pendingOrderData) => {
    try {
      // Determine the API endpoint based on the environment
      const serverEndpoint =
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:3001/savePendingOrder'
          : 'https://poinciana-bite-2e380da165ed.herokuapp.com/savePendingOrder';

      // Send a POST request to the server using Axios
      const response = await axios.post(serverEndpoint, pendingOrderData);

      if (response.status === 200) {
        console.log('Pending order data saved on the server:', pendingOrderData);
      } else {
        console.error('Failed to save pending order data on the server:', response.statusText);
      }
    } catch (error) {
      console.error('Error sending request to save pending order data:', error.message);
    }
  };



  const handleCustomizationChange = (section, optionName, value) => {
    setCustomizations(prev => {
      const updatedCustomizations = { ...prev };

      if (!updatedCustomizations[section]) {
        updatedCustomizations[section] = {};
      }

      if (item.customizations[0][section].type === 'checkbox') {
        if (item.customizations[0][section].limit) {
          const currentSelections = updatedCustomizations[section][optionName] || [];

          const selectedIndex = currentSelections.indexOf(value);
          if (selectedIndex === -1) {
            if (currentSelections.length < item.customizations[0][section].limit) {
              updatedCustomizations[section][optionName] = currentSelections.concat(value);
            }
          } else {
            currentSelections.splice(selectedIndex, 1);
            updatedCustomizations[section][optionName] = currentSelections;
          }
        } else {
          if (!updatedCustomizations[section][optionName]) {
            updatedCustomizations[section][optionName] = [value];
          } else {
            const index = updatedCustomizations[section][optionName].indexOf(value);
            if (index === -1) {
              updatedCustomizations[section][optionName].push(value);
            } else {
              updatedCustomizations[section][optionName].splice(index, 1);
            }
          }
        }
      } else {
        updatedCustomizations[section][optionName] = value;
      }

      return updatedCustomizations;
    });
  };

  const handlePhoneNumberSave = async (phoneNumber) => {
    // Save phone number to local storage
    localStorage.setItem('userPhoneNumber', phoneNumber);

    // Hide PhoneNumberSection
    setShowPhoneNumberSection(false);

    // Alert that you'll reach out at phoneNumber soon
    alert(`Thank you! We'll reach out at ${phoneNumber} soon. Please double check to make sure you entered the correct phone number.`);

    const pendingOrderData = {
      address: selectedAddress,
      phoneNumber: phoneNumber,
      pickupRestaurant: customizations['Enter Store Name']['Pickup Restaurant']
    };

    // Send pending order data to the server
    await savePendingOrderToServer(pendingOrderData);

    // Remove the phone number from local storage after the alert is sent
    localStorage.removeItem('userPhoneNumber');

    // Do not proceed with adding to order
    onClose();
  };



  if (!item) return null;

  return (
    <div className="menu-item-modal-overlay">
      <div className="container">
        <div className="modal-header">
          <span className="close-button" onClick={onClose}>
            <svg
              width="26"
              height="26"
              viewBox="0 0 16 16"
              fill="#000"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 5 L15 15 M15 5 L5 15"
                stroke="black"
                strokeWidth="2"
              />
            </svg>
          </span>
          <h3 className="modal-label">{item.name} - ${totalCost.toFixed(2)}</h3>
        </div>
        <div className="modal-content">
          {showPhoneNumberSection ? (
            <PhoneNumberSection onSavePhoneNumber={handlePhoneNumberSave} />
          ) : (
            <>
              {item.customizations &&
                item.customizations.map((customizationSection, index) => {
                  return Object.keys(customizationSection).map((sectionName) => {
                    const uniqueKeyForDropdown = `${index}-${sectionName}`;
                    const section = customizationSection[sectionName];
                    if (section.type === 'dropdown') {
                      return (
                        <div key={uniqueKeyForDropdown} className="customization-dropdown">
                          <h4>{sectionName} {section.required && <span className="required">*</span>}</h4>
                          {Object.keys(section.options).map((optionName) => (
                            <div key={optionName} className="option">
                              <label>
                                {optionName}
                                {section.options[optionName].map((o) => (
                                  <div key={o.option} className="custom-option">
                                    <input
                                      type="radio"
                                      name={optionName}
                                      value={o.option}
                                      defaultChecked={o.default}
                                      onChange={() =>
                                        handleCustomizationChange(
                                          sectionName,
                                          optionName,
                                          o.option
                                        )
                                      }
                                    />
                                    {o.option} {o.price > 0 ? `(+${o.price.toFixed(2)})` : ''}
                                  </div>
                                ))}
                              </label>
                            </div>
                          ))}
                        </div>
                      );
                    } else if (section.type === 'checkbox') {
                      return (
                        <div key={uniqueKeyForDropdown} className="customization-dropdown">
                          <h4>{sectionName} {section.required && <span className="required">*</span>}</h4>
                          {Object.keys(section.options).map((optionName) => (
                            <div key={optionName} className="option">
                              <label>
                                {optionName}
                                {section.options[optionName].map((o) => (
                                  <div key={o.option} className="custom-option">
                                    <input
                                      type="checkbox"
                                      name={`${sectionName}-${optionName}`}
                                      value={o.option}
                                      checked={
                                        customizations[sectionName] &&
                                          customizations[sectionName][optionName] &&
                                          customizations[sectionName][optionName].includes(o.option)
                                          ? true
                                          : false
                                      }
                                      onChange={() =>
                                        handleCustomizationChange(
                                          sectionName,
                                          optionName,
                                          o.option
                                        )
                                      }
                                    />
                                    {o.option} {o.price > 0 ? `(+${o.price.toFixed(2)})` : ''}
                                  </div>
                                ))}
                              </label>
                            </div>
                          ))}
                        </div>
                      );
                    } else if (section.type === 'label') {
                      return (
                        <div key={uniqueKeyForDropdown} className="customization-label">
                          <h4>{sectionName} {section.required && <span className="required">*</span>}</h4>
                          {Object.keys(section.options).map((optionName) => (
                            <div key={optionName} className="option">
                              <label>
                                {section.options[optionName][0].option} {section.options[optionName][0].price > 0 ? `(+${section.options[optionName][0].price.toFixed(2)})` : ''}
                              </label>
                            </div>
                          ))}
                        </div>
                      );
                    } else if (section.type === 'input') {
                      return (
                        <div key={uniqueKeyForDropdown} className="customization-input">
                          <h4>{sectionName} {section.required && <span className="required">*</span>}</h4>
                          {Object.keys(section.options).map((optionName) => (
                            <div key={optionName} className="option">
                              <label>
                                <input
                                  type="text"
                                  name={`${sectionName}-${optionName}`}
                                  value={customizations[sectionName] ? customizations[sectionName][optionName] || '' : ''}
                                  onChange={(e) => handleCustomizationChange(sectionName, optionName, e.target.value)}
                                />
                                {section.options[optionName][0].price > 0 ? `(+${section.options[optionName][0].price.toFixed(2)})` : ''}
                              </label>
                            </div>
                          ))}
                        </div>
                      );
                    }
                    return null;
                  });
                })}
            </>
          )}
          <div className="quantity-control">
            <button onClick={handleSubtractQuantity}>-</button>
            <span>{quantity}</span>
            <button onClick={handleAddQuantity}>+</button>
          </div>
        </div>
        <div className="modal-footer">
          <DoneButton
            onClick={handleAddToOrderClick}
            label="Add to order"
            disabled={!requiredCustomizationsSelected && !showPhoneNumberSection}
          />
        </div>
      </div>
    </div>
  );
}

MenuItemModal.propTypes = {
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddToOrder: PropTypes.func.isRequired,
};

export default MenuItemModal;
