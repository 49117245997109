// CheckoutPageCustom.js

import React, { useState, useEffect } from 'react';
import '../styles/CheckoutPage.css';
import { useParams } from 'react-router-dom';
import { useAddressSelection } from '../context/AddressSelectionContext';
import { useCurrentOrder } from '../context/CurrentOrderContext';
import { useSelectedStore } from '../context/SelectedStoreContext';
import axios from 'axios';

import PhoneNumberSection from '../components/PhoneNumberSection';

function CheckoutPageCustom({ onBackToCart }) {
    const { id } = useParams();
    console.log(id);

    const [orderDetails, setOrderDetails] = useState(null);

    useEffect(() => {
        const apiUrl = process.env.NODE_ENV === 'development'
          ? 'http://localhost:3001'
          : 'https://poinciana-bite-2e380da165ed.herokuapp.com';
      
        const fetchOrderDetails = async () => {
          try {
            const response = await axios.get(`${apiUrl}/order-details/${id}`);
            const data = response.data;
      
            console.log(data);
            let cartItems = [
              {
                name: "Custom Order",
                totalCost: data.subtotal,
                customizations: {},
                quantity: 1,
              }
            ];
            // Set orderDetails with the updated cartItems and deliveryFee
            setOrderDetails({
              ...data,
              cartItems,
              deliveryFee: data.deliveryFee // Update deliveryFee
            });
      
            setDeliveryFee(data.deliveryFee);
      
            // Set phoneNumber in localStorage
            localStorage.setItem('userPhoneNumber', data.phoneNumber);
            setUserPhoneNumber(data.phoneNumber)
          } catch (error) {
            console.error('Error fetching order details:', error);
          }
        };
      
        fetchOrderDetails();
      }, [id]);
      
    
    
    

    const { selectedAddress } = useAddressSelection();
    const { currentOrders } = useCurrentOrder();
    const [tipPercentage, setTipPercentage] = useState(null);
    const [customTip, setCustomTip] = useState(0);
    const [showCustomTipModal, setShowCustomTipModal] = useState(false);
    const [computedTip, setComputedTip] = useState(0);
    const [totalWithTip, setTotalWithTip] = useState(0);
    const [userPhoneNumber, setUserPhoneNumber] = useState("");
    const [showPhoneNumberSection, setShowPhoneNumberSection] = useState(false);
    const { selectedStore } = useSelectedStore();
    const [deliveryFee, setDeliveryFee] = useState(0);

    let subtotal = orderDetails?.subtotal || 0;
    let taxes = orderDetails?.taxes || (subtotal * 0.075);
    let serviceFee = orderDetails?.serviceFee || ((subtotal + taxes) * 0.029 + 0.30);
    let total =
        parseFloat(subtotal.toFixed(2)) +
        parseFloat(orderDetails?.deliveryFee.toFixed(2)) +
        parseFloat(taxes.toFixed(2)) +
        parseFloat(serviceFee.toFixed(2));

    useEffect(() => {
        const storedPhoneNumber = localStorage.getItem('userPhoneNumber');
        if (!storedPhoneNumber) {
            setShowPhoneNumberSection(true);
        } else {
            setUserPhoneNumber(storedPhoneNumber);
        }
    }, []);

    useEffect(() => {
        setComputedTip(parseFloat(customTip));
    }, [customTip]);

    useEffect(() => {
        const newTotalWithTip = total + computedTip;
        setTotalWithTip(newTotalWithTip);
    }, [computedTip, total]);

    const handleTipSelection = (percentage) => {
        setTipPercentage(percentage);
        let calculatedTip = percentage / 100 * subtotal;
        setComputedTip(calculatedTip);
        if (percentage === null) {
            setCustomTip(0);
        }
    };

    const handleCustomTipClick = () => {
        setShowCustomTipModal(true);
    };

    const handleCustomTipConfirmation = () => {
        setShowCustomTipModal(false);
    };

    const handleCustomTipChange = (value) => {
        setCustomTip(value);
    };

    const handleSavePhoneNumber = (phoneNumber) => {
        localStorage.setItem('userPhoneNumber', phoneNumber);
        setShowPhoneNumberSection(false);
        setUserPhoneNumber(phoneNumber);
    };

    const handlePayButtonClick = async () => {
        try {
          const apiUrl = process.env.NODE_ENV === 'development'
            ? 'http://localhost:3001'
            : 'https://poinciana-bite-2e380da165ed.herokuapp.com';
      
          const response = await axios.post(`${apiUrl}/create-checkout-session`, {
            totalWithTip,
            selectedAddress,
            deliveryFee,
            cartItems: orderDetails?.cartItems || [],
            userPhoneNumber,
          });
      
          const { sessionURL } = response.data;
          window.location.href = sessionURL;
        } catch (error) {
          console.error('Error creating checkout session:', error);
          window.alert('Failed to create checkout session. Please try again later.');
        }
      };
      
    

    function formatPhoneNumber(phoneNumber) {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return match[1] + '-' + match[2] + '-' + match[3];
        }
        return phoneNumber;
    }

    return (
        <div className="checkout-page-overlay">
            <div className="container">
                {showPhoneNumberSection && (
                    <PhoneNumberSection onSavePhoneNumber={handleSavePhoneNumber} />
                )}
                <div className="modal-header">
                    <span
                        className="back-button"
                        onClick={() => {
                            showPhoneNumberSection
                                ? setShowPhoneNumberSection(false)
                                : window.location.href = "/";
                            }}
                    >
                        <svg
                            width="26"
                            height="26"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M15.41 16.09L10.83 11.5L15.41 6.91L14 5.5L8 11.5L14 17.5L15.41 16.09Z"
                                fill="none"
                                stroke="black"
                                strokeWidth="2"
                            />
                        </svg>
                    </span>
                    <h3 className="modal-label">Checkout</h3>
                </div>
                <div className="address-display">
                    Delivering to: {orderDetails ? orderDetails.address : ''}
                </div>
                <div className="modal-content">
                    {orderDetails?.cartItems && orderDetails.cartItems.map((item, index) => (
                            <div className="checkout-item-wrapper" key={index}>
                                <div className="checkout-item">
                                    <span className="item-name">{item.name}</span>
                                    <span className="checkout-item-price">
                                        ${item.totalCost.toFixed(2)}
                                    </span>
                                </div>
                                {item.customizations &&
                                    Object.keys(item.customizations).length > 0 && (
                                        <div className="item-customizations">
                                            <span className="customization-label">Customizations:</span>
                                            {Object.values(item.customizations)
                                                .flatMap((details) =>
                                                    Object.entries(details).map(([key, value]) => (
                                                        <div key={key} className="customization-detail">
                                                            {key} ({value})
                                                        </div>
                                                    ))
                                                )}
                                        </div>
                                    )}
                            </div>
                        ))}
                </div>
                <div className="tip-section">
                    <h4>Would you like to leave a tip?</h4>
                    <div className="tip-buttons">
                        <button className="tip-button no-tip" onClick={() => handleTipSelection(0)}>
                            No Tip
                        </button>
                        <button className="tip-button" onClick={() => handleTipSelection(15)}>
                            15%
                        </button>
                        <button className="tip-button" onClick={() => handleTipSelection(20)}>
                            20%
                        </button>
                        <button className="tip-button" onClick={() => handleTipSelection(25)}>
                            25%
                        </button>
                        <button className="tip-button custom-tip" onClick={handleCustomTipClick}>
                            Custom Tip
                        </button>
                    </div>
                </div>
                {showCustomTipModal && (
                    <div className="custom-tip-modal">
                        <h4>Enter Custom Tip Amount</h4>
                        <input
                            type="number"
                            placeholder="Tip"
                            value={customTip > 0 ? customTip : ''}
                            onChange={(e) => handleCustomTipChange(e.target.value)}
                        />
                        <button onClick={handleCustomTipConfirmation}>Confirm</button>
                    </div>
                )}
                <div className="checkout-summary">
                    <div className="summary-item">
                        <span>Subtotal:</span>
                        <span>${subtotal.toFixed(2)}</span>
                    </div>
                    <div className="summary-item">
                        <span>Delivery Fee:</span>
                        <span>${deliveryFee.toFixed(2)}</span>
                    </div>
                    <div className="summary-item">
                        <span>Taxes (7.5%):</span>
                        <span>${taxes.toFixed(2)}</span>
                    </div>
                    <div className="summary-item">
                        <span>Service Fee:</span>
                        <span>${serviceFee.toFixed(2)}</span>
                    </div>
                    <div className="summary-item">
                        <span>Tip:</span>
                        <span>${computedTip.toFixed(2)}</span>
                    </div>
                    <div className="summary-item total">
                        <span>Total:</span>
                        <span>${parseFloat(totalWithTip).toFixed(2)}</span>
                    </div>
                </div>
                <small className="phone-number-label">
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            setShowPhoneNumberSection(true);
                        }}
                    >
                        {formatPhoneNumber(userPhoneNumber) + ' EDIT'}
                    </a>
                </small>
                <div className="stripe-info">Payment securely processed by Stripe.</div>
                <div className="modal-footer">
                    <button className="pay-button" onClick={handlePayButtonClick}>
                        Pay
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CheckoutPageCustom;
