import React, { useState, useEffect } from 'react';
import Axios from 'axios'; // Import Axios
import IconInput from '../components/IconInput';
import BasicButton from '../components/BasicButton';
import '../styles/DeliveryAddress.css';
import location from '../images/location.png';

function DeliveryAddress() {
  const [address, setAddress] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const handleAddressChange = (inputValue) => {
    setAddress(inputValue);
  };

  const handleSubmit = () => {
  };

  useEffect(() => {
    const apiUrl = process.env.NODE_ENV === 'development'
      ? 'http://localhost:3001'
      : 'https://poinciana-bite-2e380da165ed.herokuapp.com';
  
    // Send an Axios request to fetch suggestions
    Axios.get(`${apiUrl}/suggestions?address=${address}`)
      .then((response) => {
        setSuggestions(response.data);
      })
      .catch((error) => {
        console.error('Error fetching suggestions:', error);
      });
  }, [address]);
  

  return (
    <div className="delivery-address-container">
      <div className="delivery-address-content">
        <h2 className="delivery-address-heading">Order food to your door</h2>
        <div className="input-button-container">
          <div className="icon-input-wrapper">
            <IconInput
              icon={location}
              placeholder="Enter delivery address"
              value={address}
              onChange={handleAddressChange}
              suggestionsProp={suggestions} // Use fetched suggestions
            />
          </div>
          <BasicButton label="Get started" onClick={handleSubmit} color="black" />
        </div>
      </div>
    </div>
  );
}

export default DeliveryAddress;
