import { createContext, useContext, useState, useEffect } from 'react';

// Create a context
const AddressSelectionContext = createContext();

// Create a custom hook to use the context
export function useAddressSelection() {
  return useContext(AddressSelectionContext);
}

// Create a context provider component
export function AddressSelectionProvider({ children }) {
  const [selectedAddress, setSelectedAddress] = useState('');

  // Function to set the selected address and save it to local storage
  const setAddress = (address) => {
    setSelectedAddress(address);
    localStorage.setItem('selectedAddress', address);
  };

  // Function to get the selected address from local storage
  const getStoredAddress = () => {
    const storedAddress = localStorage.getItem('selectedAddress');
    return storedAddress || '';
  };

  // Use useEffect to retrieve the address from local storage on component mount
  useEffect(() => {
    const storedAddress = getStoredAddress();
    setSelectedAddress(storedAddress);
  }, []);

  return (
    <AddressSelectionContext.Provider value={{ selectedAddress, setAddress, getStoredAddress }}>
      {children}
    </AddressSelectionContext.Provider>
  );
}
