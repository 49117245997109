// AllOrdersPage.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../styles/AllOrdersPage.css';
import OrderModal from '../components/OrderModal';

function AllOrdersPage() {
  const [orders, setOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [password, setPassword] = useState(localStorage.getItem('password') || '');
  const [activeTab, setActiveTab] = useState('orders');
  const [showModal, setShowModal] = useState(false);
  const [selectedPendingOrder, setSelectedPendingOrder] = useState(null);
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersEndpoint = process.env.NODE_ENV === 'development'
          ? 'http://localhost:3001/orders'
          : 'https://poinciana-bite-2e380da165ed.herokuapp.com/orders';
  
        const pendingOrdersEndpoint = process.env.NODE_ENV === 'development'
          ? 'http://localhost:3001/pendingOrders'
          : 'https://poinciana-bite-2e380da165ed.herokuapp.com/pendingOrders';
  
        const responseOrders = await axios.get(ordersEndpoint);
        setOrders(responseOrders.data);
        console.log(responseOrders.data);
  
        const responsePendingOrders = await axios.get(pendingOrdersEndpoint, {
          headers: {
            'x-password': password,
          },
        });
        setPendingOrders(responsePendingOrders.data);
      } catch (error) {
        console.error('Error fetching orders or pending orders:', error);
      }
    };
  
    fetchOrders();
  }, [password]);

  useEffect(() => {
    const fetchGeneralStatus = async () => {
      try {
        const statusEndpoint = process.env.NODE_ENV === 'development'
          ? 'http://localhost:3001/status'
          : 'https://poinciana-bite-2e380da165ed.herokuapp.com/status';
  
        const response = await axios.get(statusEndpoint);
        const { online, statusMessage } = response.data;
  
        setOnlineStatus(online);
        setStatusMessage(statusMessage);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching general status:', error);
      }
    };
  
    fetchGeneralStatus();
  }, []);

  const apiUrl = process.env.NODE_ENV === 'development'
  ? 'http://localhost:3001'
  : 'https://poinciana-bite-2e380da165ed.herokuapp.com';

const markAsDelivered = (orderId) => {
  axios.put(
    `${apiUrl}/orders/${orderId}/mark-delivered`,
    {},
    {
      headers: {
        'x-password': password,
      },
    }
  )
    .then(response => {
      if (response.data.success) {
        setOrders(prevOrders => prevOrders.filter(order => order._id !== orderId));
      }
    })
    .catch(error => {
      console.error(`Error marking order ${orderId} as delivered:`, error);
    });
};

const markAsPlaced = async (pendingOrderId) => {
  try {
    const response = await axios.put(
      `${apiUrl}/pendingOrders/${pendingOrderId}/mark-placed`,
      {},
      {
        headers: {
          'x-password': password,
        },
      }
    );

    if (response.data.success) {
      setPendingOrders(prevPendingOrders => prevPendingOrders.filter(order => order._id !== pendingOrderId));
      closeModal();
    }
  } catch (error) {
    console.error(`Error marking pending order ${pendingOrderId} as placed:`, error);
  }
};

const completePendingOrder = async (pendingOrderId) => {
  try {
    const response = await axios.put(
      `${apiUrl}/pendingOrders/${pendingOrderId}/complete`,
      {},
      {
        headers: {
          'x-password': password,
        },
      }
    );

    if (response.data.success) {
      setPendingOrders(prevPendingOrders => prevPendingOrders.filter(order => order._id !== pendingOrderId));
      closeModal();
    }
  } catch (error) {
    console.error(`Error completing pending order ${pendingOrderId}:`, error);
  }
};

const updateStatus = () => {
  axios.put(
    `${apiUrl}/update-status`,
    { online: onlineStatus, statusMessage },
    {
      headers: {
        'x-password': password,
      },
    }
  )
    .then(response => {
      if (response.data.success) {
        console.log('Status updated successfully:', response.data.status);
      }
    })
    .catch(error => {
      console.error('Error updating status:', error);
    });
};

  const openModal = (pendingOrder) => {
    setSelectedPendingOrder(pendingOrder);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedPendingOrder(null);
    setShowModal(false);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    localStorage.setItem('password', newPassword);
  };

  return (
    <div className="all-orders-container">
      <h1>All Orders</h1>

      {/* Tab Navigation */}
      <div className="tab-navigation">
        <button
          onClick={() => setActiveTab('orders')}
          className={activeTab === 'orders' ? 'active-tab' : ''}
        >
          Orders
        </button>
        <button
          onClick={() => setActiveTab('pendingOrders')}
          className={activeTab === 'pendingOrders' ? 'active-tab' : ''}
        >
          Pending Orders
        </button>
        <button
          onClick={() => setActiveTab('status')}
          className={activeTab === 'status' ? 'active-tab' : ''}
        >
          Status
        </button>
      </div>

      <div>
        {/* Password Entry Form */}
        <div>
          <input
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={handlePasswordChange}
          />
          <br />
          <br />
        </div>

        {/* Orders List */}
        {activeTab === 'orders' && (
          orders.map(order => (
            <div key={order._id} className="order-item">
              <p>Order ID: {order._id}</p>
              <p>Total with Tip: ${order.totalWithTip.toFixed(2)}</p>
              <p>Delivery Address: {order.selectedAddress}</p>
              <Link to={`/success?id=${order._id}`} className="view-details-link">View Details</Link>
              <button onClick={() => markAsDelivered(order._id)} className="mark-delivered-button">Mark as Delivered</button>
              <hr />
            </div>
          ))
        )}

        {/* Pending Orders List */}
        {activeTab === 'pendingOrders' && (
          pendingOrders.map(pendingOrder => (
            <div key={pendingOrder._id} className="pending-order-item">
              <p>Order ID: {pendingOrder._id}</p>
              <p>Address: {pendingOrder.address}</p>
              <p>Phone Number: {pendingOrder.phoneNumber}</p>
              <p>Pickup Restaurant: {pendingOrder.pickupRestaurant}</p>
              <button onClick={() => openModal(pendingOrder)} className="complete-order-button">Complete Order</button>
              <button onClick={() => markAsPlaced(pendingOrder._id)} className="mark-placed-button">Mark as Placed</button>
              <hr />
            </div>
          ))
        )}

        {/* Status Tab Content */}
        {activeTab === 'status' && (
          <div className="status-tab-content">
            <h2>Status Settings</h2>
            <div>
              <label>
                Online Status:
                <input
                  type="checkbox"
                  checked={onlineStatus}
                  onChange={() => setOnlineStatus(!onlineStatus)}
                />
              </label>
            </div>
            <div>
              <label>
                Status Message:
                <textarea
                  value={statusMessage}
                  onChange={e => setStatusMessage(e.target.value)}
                />
              </label>
            </div>
            <button onClick={updateStatus}>Update Status</button>
          </div>
        )}

        {showModal && selectedPendingOrder && (
          <OrderModal
            closeModal={closeModal}
            completePendingOrder={() => completePendingOrder(selectedPendingOrder._id)}
            selectedPendingOrder={selectedPendingOrder}
            // Add any other necessary props
          />
        )}
      </div>
    </div>
  );
}

export default AllOrdersPage;
