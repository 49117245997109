import React, { useState } from 'react';
import "../styles/PhoneNumberSection.css"

function PhoneNumberSection({ onSavePhoneNumber }) {
  const [userPhoneNumber, setUserPhoneNumber] = useState("");

  const handlePhoneNumberChange = (e) => {
    setUserPhoneNumber(e.target.value);
  };

  const handleSavePhoneNumber = () => {
    onSavePhoneNumber(userPhoneNumber);
  };

  return (
    <div className="phone-number-section">
      <h4>Enter Your Phone Number:</h4>
      <input
        className="phone-number-input" // Added class name
        type="tel"
        placeholder="Phone Number"
        value={userPhoneNumber}
        onChange={handlePhoneNumberChange}
      />
      <button
        className="save-button" // Added class name
        onClick={handleSavePhoneNumber}
      >
        Save
      </button>
    </div>
  );
}

export default PhoneNumberSection;
