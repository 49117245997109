import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import DeliveryAddress from './DeliveryAddress';
import DeliveryFeed from './DeliveryFeed';
import AllOrdersPage from './AllOrdersPage';
import StoreDetails from './StoreDetails'; // Import the StoreDetails component
import { useAddressSelection } from '../context/AddressSelectionContext';
import SuccessPage from './SuccessPage';
import CheckoutPageCustom from './CheckoutPageCustom'; // Import the CheckoutPageCustom component
import '../styles/FoodDelivery.css';

function FoodDelivery() {
    const { selectedAddress, getStoredAddress } = useAddressSelection();

    // Check if there is a selected address in local storage
    const hasSelectedAddressInLocalStorage = !!getStoredAddress();

    return (
        <Router>
            <div className={hasSelectedAddressInLocalStorage ? '' : 'food-delivery-container'}>
                <Routes>
                    <Route index element={hasSelectedAddressInLocalStorage ? <Navigate replace to="/feed" /> : <DeliveryAddress />} />
                    <Route path="/feed" element={hasSelectedAddressInLocalStorage ? <DeliveryFeed selectedAddress={selectedAddress} /> : <Navigate replace to="/" />} />
                    <Route path="/store/:id" element={<StoreDetails />} />
                    <Route path="/success" element={<SuccessPage />} /> {/* Add this line for the "/success" route */}
                    <Route path="/orders" element={<AllOrdersPage />} />
                    <Route path="/checkout/:id" element={<CheckoutPageCustom />} /> {/* Add this line for the "/checkout/:id" route */}
                </Routes>
            </div>
        </Router>
    );
}

export default FoodDelivery;
