// SuccessPage.js
import React, { useState, useEffect } from 'react';
import { useCurrentOrder } from '../context/CurrentOrderContext';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios'; // Import axios for making HTTP requests
import '../styles/SuccessPage.css';

function SuccessPage() {
  const { clearLocalStorage } = useCurrentOrder();
  const location = useLocation();
  const [orderId, setOrderId] = useState(null); // State to store the order ID
  const [order, setOrder] = useState(null); // State to store the fetched order

  useEffect(() => {
    const apiUrl = process.env.NODE_ENV === 'development'
      ? 'http://localhost:3001'
      : 'https://poinciana-bite-2e380da165ed.herokuapp.com';
  
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
  
    if (id) {
      // Set the orderId state if ID is available
      setOrderId(id);
  
      // Fetch the order from the server
      axios.get(`${apiUrl}/orders/${id}`)
        .then(response => {
          // Set the order state with the fetched data
          setOrder(response.data);
          clearLocalStorage();
        })
        .catch(error => {
          console.error('Error fetching order:', error);
        });
    }
  
    // Call the clearLocalStorage function when the component mounts
  }, [clearLocalStorage, location.search]);
  

  return (
    <div className="success-page-container">
      {orderId && <h1 className="success-message">Order Details for ID {orderId}</h1>}
      {orderId && <h3>For questions or updates, please call or text 786-589-1288.</h3>}
      {order && (
  <div>
    <h2>Order Summary:</h2>
    <p>Total with Tip: ${order.totalWithTip.toFixed(2)}</p>
    <p>Delivery Address: {order.selectedAddress}</p>

    <h2>Items:</h2>
    {order.cartItems.map((item, index) => (
      <div key={index} className="order-item">
        <p>Name: {item.name}</p>
        {item.category !== undefined && (

          <p>Category: {item.category}</p>
        )}

        {/* Check if item.price is defined before accessing it */}
        {item.price !== undefined && (
          <p>Price: ${item.price.toFixed(2)}</p>
        )}

        <p>Quantity: {item.quantity}</p>
        {item.customizations && (
          <div>
            <h3>Customizations:</h3>
            {Object.keys(item.customizations).map((customizationCategory, catIndex) => (
              <div key={catIndex}>
                <p>{customizationCategory}:</p>
                <ul>
                  {Object.entries(item.customizations[customizationCategory]).map(([option, value], optionIndex) => (
                    <li key={optionIndex}>{option}: {value}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
        <p>Total Cost: ${item.totalCost.toFixed(2)}</p>
        <hr />
      </div>
    ))}
  </div>
)}
            <Link to="/" className="close-link">Close</Link>

    </div>
  );
}

export default SuccessPage;
